export const tableHeader = [
    {
    label: '项目名称',
    prop: 'projectName',
    role: [1]
  },
  {
    label: '进度计划名称',
    prop: 'name',
    disabled: true,
    role: [1]
  },
  {
    label: '计划工期',
    prop: 'planTimescale',
    role: [1]
  },
  {
    label: '计划开始日期',
    prop: 'planStartTime',
    role: [1]
  },
  {
    label: '计划完成日期',
    prop: 'planEndTime',
    role: [1]
  },
  {
    label: '实际工期',
    prop: 'actualTimescale',
    role: [1]
  },
  {
    label: '实际开始日期',
    prop: 'actualStartTime',
    role: [1]
  },
  {
    label: '实际完成日期',
    prop: 'actualEndTime',
    role: [1]
  },
  {
    label: '制作人',
    prop: 'createName',
    role: [1]
  },
  {
    label: '发布时间',
    prop: 'releaseTime',
    role: [1]
  }
]

export const tableHeaderData = [
  {
    label: '测点编号',
    prop: 'code',
    disabled: true
  },
  {
    label: '监测类型',
    prop: 'monitorType'
  },
  {
    label: '监测指标',
    prop: 'quotaName'
  },
  {
    label: '预警类型',
    prop: 'warningType'
  },
  {
    label: '预警级别',
    prop: 'level',
    disabled: true
  },
  {
    label: '预警时间',
    prop: 'time',
    disabled: true,
  },
  {
    label: '监测值',
    prop: 'value'
  },
  {
    label: '阈值区间',
    prop: 'threshold'
  },
  {
    label: '处理状态',
    prop: 'status'
  },
  {
    label: '操作',
    prop: ''
  }
]