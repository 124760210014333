<template>
    <div class="model-relation">
        <el-dialog :title="clickType == 'model' ? '进度关联模型' : '进度关联工况'" :visible.sync="relationDialogVisible" id="my-dialog" class="my-dialog" :class="isFullscreen ? 'full-screen' : ''"
            :before-close="handleDialogClose" @close="$parent.isShowRelationDialog = false">
            <i v-show="showModel && clickType == 'model'" class="el-icon-full-screen full-screen-icon" @click="handleFullScreenIcon"></i>
            <div class="info-wrapper">
                <el-form :model="operateForm" ref="operateForm" label-width="150px" class="my-form">
                    <el-form-item label="施工标段名称" prop="projectName">
                        <el-input v-model="operateForm.projectName" placeholder="贺州至巴马高速公路（象州至来宾段）培森柳江特大桥施工质量监控" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="进度计划名称" prop="name">
                        <el-input v-model="operateForm.name" placeholder="请输入进度计划名称（例如：桥梁施工质量监控）" disabled></el-input>
                    </el-form-item>
                </el-form>
                <div class="show-model" v-show="clickType == 'model'">
                    <el-checkbox v-model="showModel" @change="handleCheckboxChange">
                        显示BIM模型
                    </el-checkbox>
                </div>
                <div class="tree-model-wrapper">
                    <!-- 进度计划结构树 -->
                    <div v-show="!showModel" class="plan-tree-wrapper">
                        <div class="title">
                            <el-divider class="my-divider" direction="vertical"></el-divider>
                            <span>进度计划结构树</span>
                        </div>
                        <div class="tree-wrapper">
                            <div class="search-input">
                                <el-input v-model="plankeywords" placeholder="请输入关键字" />
                                <i class="el-icon-search" @click=""></i>
                            </div>
                            <div class="name">任务名称</div>
                            <el-tree
                                class="my-tree"
                                :data="planTreeData"
                                :default-expand-all="false"
                                node-key="uid"
                                ref="planTree"
                                :filter-node-method="filterPlanTreeNode"
                                :default-checked-keys="defaultKeys"
                                :default-expanded-keys="defaultExpandKeys"
                                highlight-current
                                @node-click="handleNodeClick"
                                :props="defaultProps">
                            </el-tree>
                        </div>
                    </div>
                    <!-- 关联、解绑按钮 -->
                    <div v-show="!showModel" class="relation-btn-wrapper">
                        <div class="btn-group">
                            <el-button @click="handleRelationBtn">关联</el-button>
                            <el-button v-if="unbindType" @click="handleUnbindBtn">解绑</el-button>
                            <el-button v-else @click="submitUnbindBtn">确定解绑</el-button>
                        </div>
                    </div>
                    <!-- BIM模型结构树 -->
                    <div class="work-tree-wrapper" v-show="clickType == 'model'">
                        <div class="title">
                            <el-divider class="my-divider" direction="vertical"></el-divider>
                            <span>BIM模型结构树</span>
                        </div>
                        <div class="tree-wrapper">
                            <div class="search-input">
                                <el-input v-model="modelkeywords" placeholder="请输入关键字" />
                                <i class="el-icon-search" @click=""></i>
                            </div>
                            <div class="name">构件名称</div>
                            <el-tree
                                class="my-tree"
                                :data="modelTreeData"
                                :default-expand-all="false"
                                node-key="nodeId"
                                ref="modelTree"
                                show-checkbox
                                :filter-node-method="filterModelTreeNode"
                                :default-checked-keys="modelDefaultKeys"
                                :default-expanded-keys="modelDefaultExpandKeys"
                                @node-click="handleBimNodeClick"
                                highlight-current
                                :props="modelDefaultProps">
                                <!-- <span class="custom-tree-node" slot-scope="{ node, data }">
                                    <span :class="data.bgColor ? 'add-bg-color' : ''">{{ data.nodeName }}</span>
                                </span> -->
                            </el-tree>
                        </div>
                    </div>
                    <!-- 工况结构树 -->
                    <div class="model-tree-wrapper" v-show="clickType == 'work'">
                        <div class="title">
                            <el-divider class="my-divider" direction="vertical"></el-divider>
                            <span>工况结构树</span>
                        </div>
                        <div class="tree-wrapper">
                            <div class="search-input">
                                <el-input v-model="workKeywords" placeholder="请输入关键字" />
                                <i class="el-icon-search" @click=""></i>
                            </div>
                            <div class="name">工况名称</div>
                            <el-tree
                                class="my-tree"
                                :data="workTreeData"
                                :default-expand-all="false"
                                node-key="nodeId"
                                ref="workTree"
                                show-checkbox
                                :filter-node-method="filterWorkTreeNode"
                                :default-checked-keys="workDefaultKeys"
                                :default-expanded-keys="workDefaultExpandKeys"
                                @node-click="handleWorkNodeClick"
                                highlight-current
                                :props="workDefaultProps">
                            </el-tree>
                        </div>
                    </div>
                    <!-- 模型 -->
                    <div v-show="showModel" class="model-wrapper">
                        <iframe 
                            src="./static/BR_ModeView/index.html" 
                            id="unity-model"
                            width="100%"
                            height="100%"
                            frameborder="0"
                            ref="unityInstance"
                        >
                        </iframe>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { Loading } from "element-ui"
import screenfull from "screenfull"
export default {
    name: 'relation',
    props: ['rowData', 'clickType'],
    data() {
        return {
            token: window.sessionStorage.getItem("token"),
            projectId: sessionStorage.getItem("projectId"),
            // 关联模型弹窗
            relationDialogVisible: true,
            // 表单数据
            operateForm: {},
            // 是否显示BIM模型
            showModel: false,
            // 进度计划结构树
            planTreeData: [],
            // 进度计划结构树关键字
            plankeywords: '',
            // 默认选中的节点
            defaultKeys: [],
            // 默认展开的节点
            defaultExpandKeys: [],
            defaultProps: {
                children: 'children',
                label: 'project'
            },
            // BIM模型结构树
            modelTreeData: [],
            // BIM模型结构树关键字
            modelkeywords: '',
            modelDefaultKeys: [],
            // 默认展开的节点
            modelDefaultExpandKeys: [],
            modelDefaultProps: {
                children: 'children',
                label: 'nodeName'
            },
            // 进度计划结构树选中的节点数据
            currentSelectNode: {},
            // loading
            loadingInstance: null,
            // 关联过的节点
            defaultDisabledKeys: [],
            // 解绑/确定解绑 默认解绑
            unbindType: true,
            // 是否全屏
            isFullscreen: false,
            // 工况结构树
            workTreeData: [],
            workDefaultKeys: [],
            workKeywords: '',
            workDefaultExpandKeys: [],
            workDefaultProps: {
                children: 'children',
                label: 'nodeName'
            },
            // 当前点击的工况结构树节点
            currentWorkNode: {}
        }
    },
    watch: {
        plankeywords(val) {
            this.$refs.planTree.filter(val)
        },
        modelkeywords(val) {
            this.$refs.modelTree.filter(val)
        },
        workKeywords(val) {
            this.$refs.workTree.filter(val)
        }
    },
    created() {
        this.getPlanTreeData()
        window.addEventListener('message', this.myFunction)
        // if (this.clickType == 'work') {
        //     window.addEventListener('message', this.myFunction)
        // }
    },
    mounted() {
        console.log('接收到的row数据', this.rowData)
        this.operateForm = this.rowData
        if(this.clickType == 'work') {   
            this.getWorkTreeData()
        } else {
            this.getModelTreeData()
        }
    },
    beforeDestroy() {
        // this.ifrmamePostMessage({ Model_update: 'Model_update' })
        window.removeEventListener('message', this.myFunction)
    },
    beforeRouteLeave(to, from, next) {
        // this.ifrmamePostMessage({ Model_update: 'Model_update' })
        window.removeEventListener('message', this.myFunction)
        next()
    },
    methods: {
        // unity 获取token
        getToken(){
            this.$refs.unityInstance.contentWindow.Get_UserToken()
        },
        // unity 点击BIM树节点
        getDeviceNumber(id) {
            this.$refs.unityInstance.contentWindow.Set_SelectBimNodeId(id)
        },
        // unity 模型
        myFunction(e) {
            console.log('获取unity模型', e)
            // console.log('unity返回设备编号', e.data.handle)
            this.getToken();
        },
        // 获取进度计划结构树数据
        getPlanTreeData() {
            this.$axios.get(`${this.baseURL}base/plan/plan/tree/${this.rowData.id}`).then(res => {
                console.log('获取进度计划结构树数据', res) 
                this.planTreeData = res.data.data
                this.defaultExpandKeys = [res.data.data[0].uid]
            })
        },
        // 格式化disabled
        formatDisabledType(list) {
            list.forEach(item => {
                if (item.disabled == 'true') {
                    item.disabled = true
                    this.defaultDisabledKeys.push(item.nodeId)
                } else {
                    item.disabled = false
                }
                if (item.children && item.children.length) {
                    this.formatDisabledType(item.children)
                }
            })
        },
        // 获取BIM模型结构树数据
        getModelTreeData() {
            this.$axios.get(`${this.baseURL}base/plan/getModelTree/${this.rowData.id}/${this.projectId}`).then(res => {
                console.log('获取BIM模型结构树数据', res) 
                this.defaultDisabledKeys = []
                let detail = JSON.parse(JSON.stringify(res.data.data))
                this.formatDisabledType(detail)
                this.modelTreeData = detail
                this.modelDefaultExpandKeys = [detail[0].nodeId]
            })
        },
        // 点击BIM结构树
        handleBimNodeClick(node, data) {
            console.log('点击bim结构树', node)
            if (this.showModel) {
                this.getDeviceNumber(node.nodeId)
            }
        },
        // 获取工况结构树
        getWorkTreeData() {
            this.$axios.get(`${this.baseURL}base/plan/get/working/tree/${this.rowData.id}/${this.projectId}`).then(res => {
                console.log('获取工况结构树数据', res) 
                this.defaultDisabledKeys = []
                let detail = JSON.parse(JSON.stringify(res.data.data))
                this.formatDisabledType(detail)
                this.workTreeData = detail
                this.workDefaultExpandKeys = [detail[0].nodeId]
            })
        },
        // 点击工况结构树
        handleWorkNodeClick(node) {
            console.log('点击工况结构树-----', node)
            this.currentWorkNode = node
            this.$axios.get(`${this.baseURL}base/plan/plan/uid/data/${this.projectId}/${this.rowData.id}/${node.nodeId}`).then(res => {
                let type = typeof(res.data.data)
                if (type == 'string') {
                    // 有数据
                    this.$nextTick(() => {
                        this.defaultExpandKeys = [res.data.data]
                        this.$refs.planTree.setCurrentKey(res.data.data)
                    })
                } else {
                    // 无数据
                    this.$nextTick(() => {
                        this.$refs.planTree.setCurrentKey()
                    }) 
                }
            })
        },
        // 全部启用
        setAllEnabled(list) {
            return new Promise((resolve) => {
                if (list.length) {
                    list.forEach(item => {
                        item.disabled = false
                        if (item.children && item.children.length) {
                            this.setAllEnabled(item.children)
                        }
                    })
                }
                resolve()
            })
        },
        // 全部非高亮
        setAllIsCurrentFalse(list) {
            return new Promise((resolve) => {
                if (list.length) {
                    list.forEach(item => {
                        let node = this.$refs.modelTree.getNode(item.nodeId)
                        this.$set(node, 'isCurrent', false)
                        if (item.children && item.children.length) {
                            this.setAllIsCurrentFalse(item.children)
                        }
                    })
                }
                resolve()
            })
        },
        // 点击进度计划结构树
        handleNodeClick(data) {
            this.unbindType = true
            console.log('点击进度计划结构树', data)
            this.currentSelectNode = data
            if (this.clickType == 'work') {
                // 工况结构树
                this.$axios.get(`${this.baseURL}base/plan/plan/working/data/${this.projectId}/${this.rowData.id}/${data.uid}`).then(res => {
                    this.setAllEnabled(this.workTreeData)
                    let type = typeof(res.data.data)
                    if(type == 'string') {
                        // 有关联关系
                        let node = this.$refs.workTree.getNode(res.data.data)
                        setTimeout(() => {
                            this.$nextTick(() => {
                                this.workDefaultExpandKeys = [res.data.data]
                                this.$refs.workTree.setCurrentKey(res.data.data)
                                this.$set(node.data, 'disabled', true)
                            })
                        }, 100)
                    } else {
                        this.$refs.workTree.setCurrentKey()
                    }
                })
            } else {
                // BIM结构树
                this.$axios.post(`${this.baseURL}base/plan/view/bim/${this.rowData.id}/${data.uid}`).then(res => {
                    console.log('---------------获取进度计划关联的bim数据----------', res.data.data)
                    let detail = res.data.data
                    this.defaultDisabledKeys = detail
                    if (detail.length > 0) {
                        this.setAllIsCurrentFalse(this.modelTreeData)
                        this.modelDefaultExpandKeys = [detail[0]]
                        detail.forEach(item => {
                            let node = this.$refs.modelTree.getNode(item)
                            this.$set(node, 'isCurrent', true)
                        })
                    }
                })
            }
        },
        // BIM结构树关联
        bimRelation(data) {
            // loading
            let options = {
                fullscreen: true,
                text: "关联中...",
                customClass: "customClass",
                background: " rgba(0, 0, 0, 0.5)"
            }
            this.loadingInstance = Loading.service(options)
            this.$axios.post(`${this.baseURL}base/plan/plan/model`, data).then(res => {
                console.log('关联操作', res) 
                if (res.data.status == 200) {
                    this.$refs.modelTree.setCheckedKeys([])
                    this.$message.success('关联成功')
                    this.getModelTreeData()
                } else {
                    this.$message.error(res.data.errMsg)
                }
                if (this.loadingInstance) {
                    this.loadingInstance.close()
                    this.loadingInstance = null
                }
            })
        },
        // 工况结构树关联
        workRelation(data) {
            // loading
            let options = {
                fullscreen: true,
                text: "关联中...",
                customClass: "customClass",
                background: " rgba(0, 0, 0, 0.5)"
            }
            this.loadingInstance = Loading.service(options)
            this.$axios.post(`${this.baseURL}base/plan/plan/working/${data.projectId}/${data.planId}/${data.uid}/${data.nodeId}`).then(res => {
                console.log('关联操作', res) 
                if (res.data.status == 200) {
                    this.$refs.workTree.setCheckedKeys([])
                    this.$message.success('关联成功')
                    this.getWorkTreeData()
                } else {
                    this.$message.error(res.data.errMsg)
                }
                if (this.loadingInstance) {
                    this.loadingInstance.close()
                    this.loadingInstance = null
                }
            })
        },
        // 关联
        handleRelationBtn() {
            console.log('当前操作类型', this.clickType)
            let node = this.currentSelectNode
            if (!node.uid) {
                this.$message.warning('请选择进度计划结构树数据')
                return
            }
            if (this.clickType == 'work') {
                // 工况结构树
                let workSelect = this.$refs.workTree.getCheckedKeys()
                if (workSelect.length > 1) {
                    this.$message.warning('只能选择一个工况')
                    return
                }
                if (!workSelect.length) {
                    this.$message.warning('请选择工况结构树数据')
                    return
                }
                let data = {
                        nodeId: workSelect.toString(),
                        planId: this.rowData.id,
                        projectId: this.projectId,
                        uid: node.uid
                    }
                this.workRelation(data)
            } else {
                // BIM结构树
                let bimSelect = this.$refs.modelTree.getCheckedKeys()
                if (!bimSelect.length) {
                    this.$message.warning('请选择BIM模型结构树数据')
                    return
                }
                let data = [
                    {
                        planBim: bimSelect.toString(),
                        planId: this.rowData.id,
                        projectId: this.projectId,
                        uid: node.uid
                    }
                ]
                this.bimRelation(data)
            }
        },
        // 全部设置disabled
        setAllDisabled(list) {
            return new Promise((resolve) => {
                if (list.length) {
                    list.forEach(item => {
                        item.disabled = true
                        if (item.children && item.children.length) {
                            this.setAllDisabled(item.children)
                        }
                    })
                }
                resolve()
            })
        },
        // 解绑
        handleUnbindBtn() {
            if (!this.currentSelectNode.uid) {
                this.$message.warning('请选择进度计划结构树节点')
                return
            }
            if (this.clickType == 'work') {
                // 工况结构树
                this.$axios.post(`${this.baseURL}base/plan/plan/working/unit/${this.projectId}/${this.rowData.id}/${this.currentSelectNode.uid}`).then(res => {
                    console.log('确定解绑操作', res) 
                    if (res.data.status == 200) {
                        // this.$refs.workTree.setCheckedKeys([])
                        this.$message.success('解绑成功')
                        this.getWorkTreeData()
                    }
                })
            } else {
                // 进度计划结构树
                this.unbindType = false
                console.log('获取当前禁用的keys集合', this.defaultDisabledKeys)
                let disKeys = this.defaultDisabledKeys
                this.setAllDisabled(this.modelTreeData)
                if (disKeys && disKeys.length) {
                    this.$refs.modelTree.setCheckedKeys(disKeys)
                    disKeys.forEach(item => {
                        let node = this.$refs.modelTree.getNode(item)
                        this.$nextTick(() => {
                            this.$set(node.data, 'disabled', false)
                        })
                    })
                }
            }
        },
        // 确定解绑
        submitUnbindBtn() {
            let bimSelect = this.$refs.modelTree.getCheckedKeys()
            if (!bimSelect.length) {
                this.$message.warning('请选择BIM模型结构树数据')
                return
            }
            this.unbindType = true
            // loading
            let options = {
                fullscreen: true,
                text: "解绑中...",
                customClass: "customClass",
                background: " rgba(0, 0, 0, 0.5)"
            }
            this.loadingInstance = Loading.service(options)
            let data = [
                {
                    planBim: bimSelect.toString(),
                    planId: this.rowData.id,
                    projectId: this.projectId,
                    uid: this.currentSelectNode.uid
                }
            ]
            this.$axios.post(`${this.baseURL}base/plan/untie`, data).then(res => {
                console.log('确定解绑操作', res) 
                if (res.data.status == 200) {
                    this.$refs.modelTree.setCheckedKeys([])
                    this.$message.success('解绑成功')
                    this.getModelTreeData()
                    if (this.loadingInstance) {
                        this.loadingInstance.close()
                        this.loadingInstance = null
                    }
                }
            })
        },
        // 进度计划结构树过滤
        filterPlanTreeNode(value, data) {
            if (!value) return true
            return data.project.indexOf(value) !== -1
        },
        // BIM模型结构树过滤
        filterModelTreeNode(value, data) {
            if (!value) return true
            return data.nodeName.indexOf(value) !== -1
        },
        // 工况结构树过滤
        filterWorkTreeNode(value, data) {
            if (!value) return true
            return data.nodeName.indexOf(value) !== -1
        },
        // 关闭弹窗
        handleDialogClose() {
            this.$parent.isShowRelationDialog = false
        },
        // 全屏
        handleFullScreenIcon() {
            if (this.showModel) {
                this.isFullscreen = !this.isFullscreen
            } 
        },
        // 是否显示模型
        handleCheckboxChange(val) {
            // console.log('---', val)
            if (!val) {
                this.isFullscreen = false
            }
        }
    }
}
</script>

<style scoped lang="scss">
    /* .add-bg-color {
        color: red;
    } */
    .newstle{
        width:100vw !important;
        height: 100vh !important;
        border: 1px solid red;
    }
    ::v-deep #my-dialog.full-screen {
        div.el-dialog {
            width: 100% !important;
            height: 100% !important;
            margin-top: 0 !important;
        }
    }
    ::v-deep .el-form-item__label {
        font-size: 14px !important;
    }
    ::v-deep .el-form-item__content {
        width: 60%;
        .el-input {
            width: 100%;
        }

    }
    ::v-deep .el-checkbox__inner {
      background-color: #2667DB !important;
      border-color: #2667DB !important;
    }
    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: #2772f0 !important;
        border-color: #2772f0 !important;
    }
    /* ::v-deep .el-checkbox__inner:hover {
      border-color: #2772f0 !important;
    } */
    ::v-deep .el-checkbox__input.is-disabled .el-checkbox__inner {
        background-color: #777 !important;
        border-color: #777 !important;
    }
    ::v-deep #my-dialog {
        overflow: hidden;
        .el-dialog {
            width: 40% !important;
            height: 80%;
            margin-top: 10vh !important;
            .el-dialog__body {
                height: calc(100% - 55px);
                .full-screen-icon {
                    position: absolute;
                    top: 22px;
                    right: 55px;
                    color: #909399;
                    &:hover {
                        cursor: pointer;
                        color: #2772f0;
                    }
                }
                .info-wrapper {
                    position: relative;
                    height: calc(100% - 130px);
                    .show-model {
                        position: absolute;
                        top: 50px;
                        right: 0;
                    }
                    .el-input.is-disabled {
                        width: 100% !important;
                        .el-input__inner {
                            font-size: 12px !important;
                            background: rgba(35,51,97,0.70) !important;
                            border: 1px solid #2c487b;
                        }
                    }
                    .el-form-item__label {
                        font-size: 12px !important;
                    }
                    .tree-model-wrapper {
                        height: 100%;
                        display: flex;
                        padding: 20px;
                        .relation-btn-wrapper {
                            width: 180px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            .btn-group {
                                text-align: center;
                                line-height: 5;
                                .el-button {
                                    margin: 0;
                                    padding: 0;
                                }
                            }
                        }
                        .plan-tree-wrapper,
                        .model-tree-wrapper,
                        .work-tree-wrapper {
                            width: 360px;
                            /* width: calc((100% - 180px)/2); */
                            .title {
                                margin-bottom: 15px;
                                .my-divider {
                                    width: 2px;
                                    background: #2667db;
                                }
                            }
                            .tree-wrapper {
                                height: calc(100% - 10px);
                                padding: 10px 20px;
                                border: 1px solid #2667db;
                                background: linear-gradient(180deg,rgba(0,35,89,0.68) 1%, rgba(19,18,68,0.65));
                                .name {
                                    text-align: center;
                                    margin-top: 15px;
                                    padding-bottom: 5px;
                                    border-bottom: 1px solid #1f4273;
                                }
                                .my-tree {
                                    height: calc(100% - 100px);
                                    overflow: auto;
                                    background: none;
                                    margin-top: 15px;
                                    color: rgba(255,255,255,0.86);
                                }
                            }
                        }
                        .model-wrapper {
                            flex: 1;
                            height: calc(100% - 10px);
                            margin: 35px 0 0 20px;
                        }
                    }
                }
            }
        }
    }
    ::v-deep .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
        background: rgba(50,116,255,0.24) !important;
    }
</style>
