<template>
  <div class="progress-page">
    <div class="top-wrapper">
      <div class="search-input">
        <el-input v-model="searchText" placeholder="请输入关键字（例如：项目或计划名称）" />
        <i class="el-icon-search" @click="getProgressTableData"></i>
      </div>
      <div class="btn-wrapper">
        <div @click="downloadTemplate" class="download-div" :class="!myUpload ? 'disable_icon' : ''">
          <i class="el-icon-download"></i>
          <span class="download-text">下载模板</span>
        </div>
        <el-button @click="handleImportBtn" :class="!myUpload ? 'disable_icon' : ''">导入</el-button>
        <el-button @click="handleAddBtn" :class="!myUpload ? 'disable_icon' : ''">新增</el-button>
        <el-button @click="handleRelationBtn" :class="!myRelation ? 'disable_icon' : ''">进度关联模型</el-button>
        <el-button @click="handleWorkMangeBtn" :class="!myRelation ? 'disable_icon' : ''">进度关联工况</el-button>
      </div>
    </div>
    <div class="table-wrapper">
      <div class="table-top">
        <div class="name">
          <el-divider class="my-divider" direction="vertical"></el-divider>
          <span class="title">进度计划</span>
        </div>
        <img @click="getWin" :src="isFold ? require('@/assets/images/img/set/xianshi_icon.png') : require('@/assets/images/img/set/shouqi_icon.png')" alt="" />
      </div>
      <div class="bottom" v-show="!isFold">
        <my-table :tabledata="data" ref="mytable" :headerdata="filterTableHeader" @rowClick="handleRowClick" highlight-current-row @operateClick="handleOperateClick" @handleEditBtn="handleEditOperate" @filterHeader="filterHeader"> </my-table>
        <common-pagination class="pageMain" :total="total" :page-size="pageSize" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
      </div>
      <!-- <div class="tableList winStyle">
                <div class="main">
                    <div class="top box justify center">
                        <div>进度计划</div>
                        <div @click="getWin" class="pointer">
                            <img src="@/assets/images/img/set/shouqi_icon.png" alt="" />
                        </div>
                    </div>
                    
                </div>
            </div> -->
    </div>
    <div class="progress-top">
      <div class="name">
        <el-divider class="my-divider" direction="vertical"></el-divider>
        <span class="title">实际进度</span>
      </div>
      <img @click="getFoldStatus" :src="isFoldStatus ? require('@/assets/images/img/set/xianshi_icon.png') : require('@/assets/images/img/set/shouqi_icon.png')" alt="" />
    </div>
    <div class="progress-wrapper" v-show="!isFoldStatus">
      <div class="bottom">
        <DxGantt
          @task-dbl-click="onTaskDblClick"
          @context-menu-preparing="onContextMenuPreparing"
          :hoverStateEnabled="true"
          ref="ganttRef"
          :task-list-width="930"
          height="100%"
          scale-type="weeks"
          format="yyyy-MM-dd"
          :root-value="0"
          :start-date-range="startDateRange"
          task-tooltip-content-template="myTooltipContentTemplate"
        >
          <template #myTooltipContentTemplate="{ data: tasks }">
            <div class="custom-task-edit-tooltip">
              <div class="custom-tooltip-title">{{ tasks.project }}</div>
              <div>
                <span>计划开始时间：</span>
                {{ formatDayDate(tasks.planStartTime) }}
              </div>
              <div>
                <span>计划结束时间：</span>
                {{ formatDayDate(tasks.planEndTime) }}
              </div>
              <div>
                <span>实际开始时间：</span>
                {{ formatDayDate(tasks.actualStartTime) }}
              </div>
              <div>
                <span>实际结束时间：</span>
                {{ formatDayDate(tasks.actualEndTime) }}
              </div>
            </div>
          </template>
          <DxTasks :data-source="tasks" format="yyyy-MM-dd" key-expr="id" title-expr="project" start-expr="planStartTime" end-expr="planEndTime" progress-expr="planProgress" />
          <DxEditing :enabled="false" />
          <DxColumn :minWidth="150" data-field="project" caption="任务名称" />
          <DxColumn :width="100" data-field="status" caption="状态" />

          <DxColumn :width="120" data-field="planStartTime" data-type="date" caption="计划开始日期" format="yyyy-MM-dd" />
          <DxColumn :width="120" data-field="planEndTime" data-type="date" caption="计划结束日期" format="yyyy-MM-dd" />
          <DxColumn :width="100" data-field="planTimescale" caption="计划工期" />

          <DxColumn :width="120" data-field="actualStartTime" data-type="date" caption="实际开始日期" format="yyyy-MM-dd" />
          <DxColumn :width="120" data-field="actualEndTime" data-type="date" caption="实际完成日期" format="yyyy-MM-dd" />
          <DxColumn :width="100" data-field="actualTimescale" caption="实际工期" />
        </DxGantt>
      </div>
      <!-- <div class="gantt-wrapper">
                
            </div> -->
      <!-- <div class="tableList winStyle">
                <div class="main">
                    <div class="top box justify center">
                        <div>实际进度</div>
                        <div @click="getFoldStatus" class="pointer">
                            <img src="@/assets/images/img/set/shouqi_icon.png" alt="" />
                        </div>
                    </div>
                    <div class="bottom" v-show="!isFoldStatus">
                        
                    </div>
                </div>
            </div> -->
    </div>
    <!-- 新增、编辑、导入 -->
    <el-dialog :title="operateType == 'add' ? '新增' : operateType == 'edit' ? '编辑' : '导入'" :visible.sync="progressDialogVisible" class="my-dialog" top="10vh" :before-close="handleDialogClose">
      <div class="report-wrapper">
        <el-form :model="operateForm" :rules="formRules" ref="operateForm" label-width="110px" class="my-form">
          <el-form-item label="施工标段名称" prop="sectionName">
            <el-input v-model="operateForm.sectionName" placeholder="贺州至巴马高速公路（象州至来宾段）培森柳江特大桥施工质量监控" disabled></el-input>
          </el-form-item>
          <el-form-item label="进度计划名称" prop="name">
            <el-input v-model="operateForm.name" placeholder="请输入进度计划名称（例如：桥梁施工质量监控）"></el-input>
          </el-form-item>
        </el-form>
        <div class="btn-footer">
          <el-upload class="upload-demo" :headers="{ token }" :on-success="onsuccess" ref="upload" :action="baseURL + 'base/plan/import'" :show-file-list="false">
            <el-button class="import-btn">导入Excel或Project进度计划</el-button>
          </el-upload>
          <el-button class="save-btn" @click="handleSave">保存</el-button>
          <el-button class="cancel-btn" @click="handleDialogClose">取消</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 编辑gantt -->
    <el-dialog title="编辑施工进度" :visible.sync="editProgressDialogVisible" class="my-dialog edit-dialog" top="15vh" :before-close="handleEditDialogClose">
      <div class="report-wrapper">
        <el-form :model="ganttForm" ref="ganttForm" label-width="100px" class="my-form">
          <el-form-item label="任务名称" prop="project">
            <el-input v-model="ganttForm.project" placeholder="请输入任务名称"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-input v-model="ganttForm.status" placeholder="状态" disabled></el-input>
          </el-form-item>
          <el-form-item label="计划开始日期" prop="planStartTime">
            <el-date-picker v-model="ganttForm.planStartTime" @change="planStartChange" :picker-options="planStartTimeOption" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" type="date" placeholder="计划开始时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="计划结束日期" prop="planEndTime">
            <el-date-picker v-model="ganttForm.planEndTime" @change="planEndChange" :picker-options="planEndTimeOption" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" type="date" placeholder="计划结束时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="计划工期" prop="planTimescale">
            <el-input v-model="ganttForm.planTimescale" placeholder="计划工期" disabled></el-input>
          </el-form-item>
          <el-form-item label="实际开始日期" prop="actualStartTime">
            <el-date-picker v-model="ganttForm.actualStartTime" @change="actualStartChange" :picker-options="actualStartTimeOption" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" type="date" placeholder="实际开始时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="实际完成日期" prop="actualEndTime">
            <el-date-picker v-model="ganttForm.actualEndTime" @change="actualEndChange" :picker-options="actualEndTimeOption" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" type="date" placeholder="实际结束时间"></el-date-picker>
          </el-form-item>
          <el-form-item label="实际工期" prop="actualTimescale">
            <el-input v-model="ganttForm.actualTimescale" placeholder="实际工期" disabled></el-input>
          </el-form-item>
        </el-form>
        <div class="btn-footer">
          <el-button class="save-btn" @click="handleGanttSave">确认</el-button>
          <el-button class="cancel-btn" @click="handleGanttCancel">取消</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 进度模型关联 -->
    <model-relation v-if="isShowRelationDialog" :rowData="currentRow" :clickType="clickType"></model-relation>
  </div>
</template>

<script>
import { DxGantt, DxTasks, DxDependencies, DxResources, DxResourceAssignments, DxColumn, DxEditing, DxToolbar, DxItem, StripLine } from "devextreme-vue/gantt";
import { DxPopup, DxPosition, DxToolbarItem } from "devextreme-vue/popup";
import DxCheckBox from "devextreme-vue/check-box";
import DxNumberBox from "devextreme-vue/number-box";
import DxDateBox from "devextreme-vue/date-box";
import DxSelectBox from "devextreme-vue/select-box";
import DxTextBox from "devextreme-vue/text-box";
import { exportGantt as exportGanttToPdf } from "devextreme/pdf_exporter";
import DxButton from "devextreme-vue/button";
import notify from "devextreme/ui/notify";
import zhMessages from "devextreme/localization/messages/zh.json";
import { locale, loadMessages, formatDate, parseDate } from "devextreme/localization";
import axios from "axios";
const ganttRef = "ganttRef";

// import { tableHeader } from "@/views/data.js";
import MyTable from "@/components/DynamicTable.vue";
import CommonPagination from "@/components/Pagination";
import ModelRelation from "./components/Relation";
export default {
  name: "progressPage",
  data() {
    return {
      token: window.sessionStorage.getItem("token"),
      projectId: sessionStorage.getItem("projectId"),
      total: 0,
      currentPage: 1,
      pageSize: 4,
      // 搜索关键字
      searchText: "",
      // 进度计划表格数据
      data: [],
      // 进度计划表格 是否折叠
      isFold: false,
      // 实际进度表格 是否折叠
      isFoldStatus: false,
      tableHeader: [],
      filterTableHeader: [],
      // 默认表头字段
      defaultData: [
        {
          label: "进度计划名称",
          prop: "name"
        }
      ],
      // gantt tasks
      tasks: [],
      // 新增、编辑、导入弹窗
      progressDialogVisible: false,
      // 操作类型
      operateType: "",
      operateForm: {},
      sectionName: "",
      formRules: {
        sectionName: [{ required: true, message: "请输入施工标段名称", trigger: "blur" }],
        name: [{ required: true, message: "请输入进度计划名称", trigger: "blur" }]
      },
      // 当前行
      currentRow: {},
      // 进度模型关联
      isShowRelationDialog: false,
      // 进度关联模型or进度关联工况
      clickType: "",
      // 导入数据
      importData: [],
      // 编辑施工进度
      editProgressDialogVisible: false,
      ganttForm: {},
      // 双击选中的数据
      currentDblData: {},
      // gantt前一个月
      startDateRange: new Date(),
      // gantt后一个月
      endDateRange: new Date(),
      // 计划开始时间
      planStartTimeOption: {
        disabledDate: (time) => {
          let end = this.ganttForm.planEndTime;
          if (end) {
            return time.getTime() > new Date(end).getTime();
          }
        }
      },
      // 计划结束时间
      planEndTimeOption: {
        disabledDate: (time) => {
          let start = this.ganttForm.planStartTime;
          if (start) {
            return time.getTime() < new Date(start).getTime();
          }
        }
      },
      // 实际开始时间
      actualStartTimeOption: {
        disabledDate: (time) => {
          let end = this.ganttForm.actualEndTime;
          if (end) {
            return time.getTime() > new Date(end).getTime();
          }
        }
      },
      // 实际结束时间
      actualEndTimeOption: {
        disabledDate: (time) => {
          let start = this.ganttForm.actualStartTime;
          if (start) {
            return time.getTime() < new Date(start).getTime();
          }
        }
      }
    };
  },
  created() {
    this.getTableColumns();
    this.getSectionName();
    this.getProgressTableData();
    setTimeout(() => {
      this.getGanttData();
    }, 1000);
  },
  computed: {
    gantt() {
      return this.$refs[ganttRef].instance;
    }
  },
  methods: {
    getTableColumns() {
      let userId = sessionStorage.getItem("userId");
      this.$axios.post(`${this.baseURL}base/plan/select/plan/fields/${this.projectId}/${userId}`).then((res) => {
        console.log("获取动态表格展示字段123123123----", res);
        let detail = res.data.data;
        if (detail.length > 0) {
          this.filterTableHeader = res.data.data;
        } else {
          this.filterTableHeader = this.defaultData;
        }
      });
    },
    getWin() {
      this.isFold = !this.isFold;
      this.getGanttData();
    },
    getFoldStatus() {
      this.isFoldStatus = !this.isFoldStatus;
    },
    // 获取施工标段名称
    getSectionName() {
      this.$axios.get(`${this.baseURL}section/info/${this.projectId}`).then((res) => {
        if (res.status == "200") {
          this.sectionName = res.data.data.name;
          this.$set(this.operateForm, "sectionName", res.data.data.name);
        }
      });
    },
    // 获取进度计划表格数据
    getProgressTableData() {
      let data = {
        name: this.searchText,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        projectId: this.projectId
      };
      this.$axios.post(`${this.baseURL}base/plan/plans`, data).then((res) => {
        console.log("获取进度计划表格数据", res);
        let detail = res.data.data;
        this.data = detail.records;
        this.total = detail.total;
        this.currentRow = detail.records[0];
      });
    },
    // 获取gantt数据
    getGanttData() {
      this.$axios.get(`${this.baseURL}base/plan/task/${this.currentRow.id}`).then((res) => {
        console.log("获取gantt数据", res);
        let detail = res.data.data;
        if (detail.list.length) {
          detail.list.forEach((item) => {
            if (item.planEndTime) {
              item.planEndTime = item.planEndTime.split(" ")[0] + " 23:59:59";
            }
          });
          this.tasks = detail.list;
        }
        if (detail.monthBefore) {
          let before = detail.monthBefore.split(" ")[0];
          this.startDateRange = new Date(before);
        }
        if (detail.monthAfter) {
            let after = detail.monthAfter.split(' ')[0]
            this.endDateRange = new Date(after)
        }
        // this.$nextTick(() => {
        //     setTimeout(() => {
        //         this.gantt.collapseAll();
        //     }, 300);
        // });
      });
    },
    // 下载模板
    downloadTemplate() {
      if (!this.myUpload) {
        this.$message.error("您没有下载权限！");
        return;
      }
      axios({
        url: `${this.baseURL}base/plan/download/template`,
        method: "get",
        responseType: "blob",
        headers: {
          token: this.token
        }
      })
        .then((res) => {
          console.log("导出模板", res);

          let a = document.createElement("a");
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
          let objectUrl = URL.createObjectURL(blob);
          let name = "进度计划导入模板";
          a.setAttribute("href", objectUrl);
          a.setAttribute("download", `${name}.xlsx`);
          a.click();
        })
        .catch((err) => {});
    },
    // 导入
    handleImportBtn() {
      if (!this.myUpload) {
        this.$message.error("您没有导入权限！");
        return;
      }
      this.operateType = "import";
      this.progressDialogVisible = true;
      this.$set(this.operateForm, "sectionName", this.sectionName);
    },
    // 导入成功
    onsuccess(res, file, fileList) {
      console.log(res, file, fileList);
      if (res.errCode == 200) {
        this.$message.success("导入成功");
        console.log("导入数据", res);
        this.importData = res.data;
      } else {
        this.$message.error(res.errMsg);
      }
    },
    // 新增
    handleAddBtn() {
      if (!this.myUpload) {
        this.$message.error("您没有新增权限！");
        return;
      }
      this.operateType = "add";
      this.progressDialogVisible = true;
      this.$set(this.operateForm, "sectionName", this.sectionName);
    },
    // 进度关联模型
    handleRelationBtn() {
      if (!this.myRelation) {
        this.$message.error("您没有关联权限！");
        return;
      }
      this.clickType = "model";
      this.isShowRelationDialog = true;
    },
    // 进度工况管理
    handleWorkMangeBtn() {
      if (!this.myRelation) {
        this.$message.error("您没有关联权限！");
        return;
      }
      this.clickType = "work";
      this.isShowRelationDialog = true;
    },
    // 点击进度计划表格行
    handleRowClick(row) {
      console.log("点击了表格行", row);
      this.currentRow = row;
      this.getGanttData();
    },
    // 表格thead
    filterHeader(filter) {
      console.log("filter--", filter);
      this.getTableColumns();
      // this.filterTableHeader = filter
      // this.tableHeader = filter
      // let tbHeaArr = [];
      // let n = this.tableHeader.length;
      // for (let i = 0; i < n; i++) {
      //     if (filter.includes(this.tableHeader[i].label)) {
      //         tbHeaArr.push(this.tableHeader[i]);
      //     }
      //     this.filterTableHeader = tbHeaArr;
      // }
    },
    // 操作列
    handleOperateClick() {
      this.getProgressTableData();
    },
    // 编辑
    handleEditOperate(row) {
      if (!this.myOption) {
        this.$message.error("您没有修改权限！");
        return;
      }
      console.log("999999999999999999999999999999999999999");
      this.operateType = "edit";
      this.progressDialogVisible = true;
      this.operateForm = row;
      console.log(row);
    },
    // 分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getProgressTableData();
    },
    // 关闭新增、编辑、导入弹窗
    handleDialogClose() {
      this.progressDialogVisible = false;
      this.operateForm = {};
      this.$refs.operateForm.resetFields();
    },
    // 时间转换函数
    formatTen(num) {
      return num > 9 ? num + "" : "0" + num;
    },
    // 时间格式化
    formatDate(date) {
      var date = new Date(date);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hour = date.getHours();
      var minute = date.getMinutes();
      var second = date.getSeconds();
      return year + "-" + this.formatTen(month) + "-" + this.formatTen(day) + " " + this.formatTen(hour) + ":" + this.formatTen(minute) + ":" + this.formatTen(second);
    },
    formatDayDate(date) {
      if (date) {
        let time = this.formatDate(date);
        return time.split(" ")[0];
      } else {
        return "";
      }
    },
    // 双击gantt编辑
    onTaskDblClick(e) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }

      console.log("双击了gantt", e);
      this.currentDblData = e.data;
      let detail = JSON.parse(JSON.stringify(e.data));
      if (!isNaN(Date.parse(detail.planStartTime)) && new Date(detail.planStartTime).getFullYear() != "1970") {
        detail.planStartTime = this.formatDate(detail.planStartTime);
      } else {
        detail.planStartTime = null;
      }
      if (!isNaN(Date.parse(detail.planEndTime)) && new Date(detail.planEndTime).getFullYear() != "1970") {
        detail.planEndTime = this.formatDate(detail.planEndTime);
      } else {
        detail.planEndTime = null;
      }
      this.ganttForm = detail;
      e.cancel = true;
      this.editProgressDialogVisible = true;
    },
    onContextMenuPreparing(e) {
      e.cancel = true;
    },
    // 保存
    handleSave() {
      this.$refs.operateForm.validate((valid) => {
        if (valid) {
          if (this.operateType == "import") {
            if (!this.importData.length) {
              this.$message.warning("请先导入数据");
              return;
            }
          }
          let data = {
            planName: this.operateForm.name,
            projectId: this.projectId,
            tasks: this.importData
          };
          if (this.operateType == "edit") {
            data.planId = this.currentRow.id;
          }
          this.$axios.post(`${this.baseURL}base/plan/save`, data).then((res) => {
            console.log("保存", res);
            if (res.status == 200) {
              this.progressDialogVisible = false;
              this.$message.success("保存成功");
              this.getProgressTableData();
              this.getGanttData();
            }
          });
        }
      });
    },
    // 关闭编辑施工进度弹窗
    handleEditDialogClose() {
      this.editProgressDialogVisible = false;
    },
    // 编辑gant提交
    handleGanttSave() {
      if (!isNaN(this.ganttForm.planStartTime)) {
        this.ganttForm.planStartTime = null;
      }
      if (!isNaN(this.ganttForm.planEndTime)) {
        this.ganttForm.planEndTime = null;
      } else {
        this.ganttForm.planEndTime = this.ganttForm.planEndTime.split(" ")[0] + " 23:59:59";
      }
      if (!isNaN(this.ganttForm.actualStartTime)) {
        this.ganttForm.actualStartTime = null;
      }
      if (!isNaN(this.ganttForm.actualEndTime)) {
        this.ganttForm.actualEndTime = null;
      } else {
        this.ganttForm.actualEndTime = this.ganttForm.actualEndTime.split(" ")[0] + " 23:59:59";
      }

      if (this.tasks.length) {
        this.tasks.forEach((item, index) => {
          if (item.id == this.ganttForm.id) {
            this.tasks[index] = this.ganttForm;
            this.currentDblData = item;
          }
        });
      }
      this.currentDblData.planStartTime = this.ganttForm.planStartTime;
      this.currentDblData.planEndTime = this.ganttForm.planEndTime;
      this.currentDblData.actualStartTime = this.ganttForm.actualStartTime;
      this.currentDblData.actualEndTime = this.ganttForm.actualEndTime;
      this.currentDblData.status = this.ganttForm.status;
      this.currentDblData.planTimescale = this.ganttForm.planTimescale;
      this.currentDblData.actualTimescale = this.ganttForm.actualTimescale;
      this.currentDblData.project = this.ganttForm.project;

      console.log("9999", this.currentDblData);

      this.$axios.post(`${this.baseURL}base/plan/update/gant`, this.currentDblData).then((res) => {
        if (res.status == 200) {
          this.editProgressDialogVisible = false;
          this.$message.success("编辑成功");
          this.getProgressTableData();
          this.getGanttData();
          this.currentDblData = {};
        }
      });
      // console.log('编辑后的整个tasks数据', this.tasks)
      // let data = {
      //     planId: this.currentRow.id,
      //     planName: this.currentRow.name,
      //     projectId: this.projectId,
      //     tasks: this.tasks
      // }
      // this.$axios.post(`${this.baseURL}base/plan/save`, data).then(res => {
      //     console.log('编辑gantt提交', res)
      //     if (res.status == 200) {
      //         this.editProgressDialogVisible = false
      //         this.$message.success('编辑成功')
      //         this.getProgressTableData()
      //         this.getGanttData()
      //     }
      // })
    },
    // 编辑gantt取消
    handleGanttCancel() {
      this.editProgressDialogVisible = false;
    },
    // 工期计算格式化
    formateDate(start, end) {
      if (start && end) {
        let s = new Date(start).getTime();
        let e = new Date(end).getTime();
        let n = e - s;
        // 工期计算：结束日期 - 开始日期 + 1
        return String(n / (1 * 24 * 60 * 60 * 1000) + 1);
      } else {
        return "";
      }
    },
    // 计划开始时间
    planStartChange(val) {
      this.ganttForm.planStartTime = val;
      if (this.ganttForm.planEndTime && val) {
        this.ganttForm.planTimescale = this.formateDate(val, this.ganttForm.planEndTime);
      } else {
        this.ganttForm.planTimescale = "";
      }
    },
    // 计划结束时间
    planEndChange(val) {
      this.ganttForm.planEndTime = val;
      if (this.ganttForm.planStartTime && val) {
        this.ganttForm.planTimescale = this.formateDate(this.ganttForm.planStartTime, val);
      } else {
        this.ganttForm.planTimescale = "";
      }
    },
    // 实际开始时间
    actualStartChange(val) {
      this.ganttForm.actualStartTime = val;
      if (this.ganttForm.actualEndTime && val) {
        this.ganttForm.actualTimescale = this.formateDate(val, this.ganttForm.actualEndTime);
      } else {
        this.ganttForm.actualTimescale = "";
      }
      let now = new Date().getTime();
      // 计算状态
      if (val) {
        let start = new Date(val).getTime();
        let time = now - start;
        if (time > 0) {
          this.$set(this.ganttForm, "status", "进行中");
        }
      } else {
        if (this.ganttForm.actualEndTime) {
          let end = new Date(this.ganttForm.actualEndTime).getTime();
          if (now - end > 0) {
            this.$set(this.ganttForm, "status", "完成");
          }
        } else {
          this.$set(this.ganttForm, "status", "");
        }
      }
    },
    // 实际结束时间
    actualEndChange(val) {
      this.ganttForm.actualEndTime = val;
      if (this.ganttForm.actualStartTime && val) {
        this.ganttForm.actualTimescale = this.formateDate(this.ganttForm.actualStartTime, val);
      } else {
        this.ganttForm.actualTimescale = "";
      }
      let now = new Date().getTime();
      // 计算状态
      if (val) {
        let done = new Date(val).getTime();
        let time = now - done;
        if (time > 0) {
          console.log("完成");
          this.$set(this.ganttForm, "status", "完成");
        }
      } else {
        if (this.ganttForm.actualStartTime) {
          let start = new Date(this.ganttForm.actualStartTime).getTime();
          if (now - start > 0) {
            this.$set(this.ganttForm, "status", "进行中");
          }
        } else {
          this.$set(this.ganttForm, "status", "");
        }
      }
    }
  },
  components: {
    ModelRelation,
    MyTable,
    CommonPagination,
    StripLine,
    DxGantt,
    DxTasks,
    DxDependencies,
    DxResources,
    DxResourceAssignments,
    DxColumn,
    DxEditing,
    DxToolbar,
    DxItem,
    DxCheckBox,
    DxNumberBox,
    DxDateBox,
    DxSelectBox,
    DxButton,
    DxPopup,
    DxPosition,
    DxToolbarItem,
    DxTextBox
  }
};
</script>

<style scoped lang="scss">
.custom-task-edit-tooltip {
  padding: 10px 15px;
  font-size: 13px;
  color: #fff;
  line-height: 1.8;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999 !important;
}
.el-dialog .btn-footer {
  text-align: center;
}
::v-deep .my-dialog.edit-dialog .el-dialog {
  width: 500px !important;
}
::v-deep .my-dialog .el-dialog {
  width: 600px !important;
  /* height: 80%; */
  border: none;
  background: linear-gradient(0deg, #0a173d 2%, #0d3772);
  .el-dialog__header {
    border-bottom: none;
    .el-dialog__title {
      font-size: 16px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.86);
    }
  }
  .el-dialog__body {
    padding: 0 20px 30px 20px;
    color: #fff;
    .report-wrapper {
      border-top: 1px solid rgba(204, 218, 255, 0.3);
      .my-form {
        margin-top: 20px;
        .el-form-item__label {
          font-size: 12px !important;
        }
        .el-input {
          width: 100% !important;
        }
        .el-input.is-disabled {
          width: 100% !important;
          .el-input__inner {
            font-size: 12px !important;
            background: rgba(35, 51, 97, 0.7) !important;
            border: 1px solid #2c487b;
          }
        }
        .el-input .el-input__inner {
          background: #0c265a;
          border: 1px solid #0e43a3;
        }
      }
      .btn-footer {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        text-align: center;
        .el-button {
          padding: 0;
          margin: 0;
          font-size: 12px;
          font-weight: 500;
        }
        .save-btn {
          margin: 0 20px;
        }
        .cancel-btn {
          background: none;
          border: 1px solid #2667db;
        }
        .import-btn {
          width: 230px;
        }
      }
    }
  }
}
.progress-page {
  width: 100%;
  height: calc(100% - 85px) !important;
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  border: 1px solid #2667db;
  .top-wrapper {
    display: flex;
    justify-content: space-between;
    height: 34px;
    line-height: 34px;
    .search-input {
      width: 25%;
      ::v-deep div.el-input .el-input__inner {
        background: #0c265a !important;
      }
      /* div.el-input input.el-input__inner {
                    
                    border: 1px solid rgba(38,103,219,0.50) !important;
                } */
    }
    .btn-wrapper {
      display: flex;
      .download-div:hover {
        cursor: pointer;
      }
      .download-div {
        display: flex;
        align-items: center;
        .download-text {
          font-size: 14px;
        }
        i {
          font-size: 20px;
          color: #2772f0;
        }
      }
      .el-button {
        margin-left: 20px;
      }
    }
  }
  .table-wrapper {
    position: relative;
    min-height: 50px;
    margin-top: 10px;
    .table-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .name {
        font-size: 14px;
        .my-divider {
          width: 2px;
          background: #2667db;
        }
      }
      img {
        width: 15px;
        height: 15px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    /* .bottom {
                height: 400px;
                overflow: auto;
            } */
    .pageMain {
      margin-top: 10px;
    }
  }
  .progress-top {
    display: flex;
    height: 20px;
    line-height: 20px;
    justify-content: space-between;
    margin: 10px 0;
    .name {
      font-size: 14px;
      .my-divider {
        width: 2px;
        background: #2667db;
      }
    }
    img {
      width: 15px;
      height: 15px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .progress-wrapper {
    flex: 1;
    overflow: auto;
    .bottom {
      height: 100%;
    }
  }
}

::v-deep .el-table .el-table__cell {
  padding: 5px 0;
}
::v-deep div.el-table td,
::v-deep div.el-table th,
::v-deep div.el-table.el-table--striped .el-table__body tr.el-table__row--striped td {
  height: 40px;
}
::v-deep div.el-table .cell {
  color: rgba(255, 255, 255, 0.86);
}
::v-deep div.el-table .el-table__body tr:hover td,
::v-deep div.el-table .el-table__body tr:hover span {
  font-weight: normal !important;
  color: rgba(255, 255, 255, 0.86) !important;
}

::v-deep .dx-treelist-headers {
  background-color: #b3ccdaff;
}

::v-deep .dx-scrollable-container {
  background-color: #b3ccdaff;
}
</style>
