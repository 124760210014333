<template>
  <div class="tablecom">
    <div class="tbopt" v-if="optOption" @click.stop="cancelDefault">
      <!-- <el-checkbox-group v-model="checkedTbHeader"> -->
      <el-checkbox-group v-model="checkedTbHeader" @change="checkedTbOptions">
        <div v-for="theader in tableHeader" :key="theader.label" class="tbopt-item">
          <el-checkbox :label="theader.label" :disabled="theader.disabled">{{ theader.label }}</el-checkbox>
        </div>
      </el-checkbox-group>
      <div class="btn-click">
        <el-button @click="confirm">确定</el-button>
        <el-button @click="optOption = false">取消</el-button>
      </div>
    </div>
    <el-table stripe :data="tabledata" class="my-table" tooltip-effect="dark" style="width: 100%" highlight-current-row @header-click="headerClick" @operate-click="handleOperateBtnClick" :header-cell-style="{ background: '#04153F', color: '#fff' }" @row-click="rowClick">
      <el-table-column v-for="(item, index) in headerdata" :key="index" :prop="item.prop" :label="item.label" :render-header="renderHeader"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <span v-show="scope.row.status == 0" class="operate-txt" @click.stop="handleTableEdit(scope.row)" :class="!myOption ? 'disable_icon' : ''">编辑</span>
          <span v-show="scope.row.status == 0" class="operate-txt" @click.stop="handleTablePublish(scope.row.id)" :class="!myOption ? 'disable_icon' : ''">未发布</span>
          <span v-show="scope.row.status == 1" class="operate-txt" @click.stop="handleTableClone(scope.row.id)" :class="!myOption ? 'disable_icon' : ''">克隆</span>
          <span v-show="scope.row.status == 1" class="operate-txt published" @click.stop="handlePublished">已发布</span>
          <span class="operate-txt" @click.stop="handleTableDelete(scope.row.id)" :class="!myDel ? 'disable_icon' : ''">删除</span>
        </template>
      </el-table-column>
      <el-table-column prop="opt" :render-header="renderHeader" width="50px"> </el-table-column>
    </el-table>
    <el-dialog :visible.sync="operateDialogVisible" class="my-dialog my-operate-dialog" top="25vh" width="500px !important" :before-close="handleDialogClose">
      <div class="content-wrapper">
        <div v-show="operateType == 'clone'" style="height: 70px"></div>
        <img v-show="operateType != 'clone'" src="@/assets/images/alert.png" alt="" />
        <div class="info">
          <div v-show="operateType == 'clone'">克隆将复制整条进度计划的记录，包括所含的甘特图内容及关联的模型（若进度计划已关联有模型的），是否克隆？</div>
          <div v-show="operateType == 'delete'">此信息可能被多处使用，删除导致其他数据不完整，确定删除吗？</div>
          <div v-show="operateType == 'publish'">此信息可能被多处使用，发布进度计划后， 将不允许修改进度计划，是否发布？</div>
        </div>
      </div>
      <div class="btn-footer">
        <el-button v-show="operateType == 'clone'" @click="handleCloneSubmit">克隆</el-button>
        <el-button v-show="operateType == 'publish'" @click="handlePublishSubmit">发布</el-button>
        <el-button v-show="operateType == 'delete'" @click="handleDeleteSubmit">确定</el-button>
        <el-button class="cancel-btn" @click="handleDialogClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import paginationVue from "../pagination.vue";
// import myButton from "@/components/form/myButton.vue";
import { tableHeader } from "../views/data.js";
import qs from "qs";
export default {
  //   components: {
  //     paginationVue,
  //     myButton
  //   },
  props: {
    tabledata: {
      required: true,
      default: () => []
    },
    headerdata: {
      required: true,
      default: () => []
    }
    // total: {
    //   required: false,
    //   type: Number,
    //   default: 8
    // },
    // page: {
    //   type: Number,
    //   default: 1
    // }
  },
  data() {
    return {
      projectId: sessionStorage.getItem("projectId"),
      userId: sessionStorage.getItem("userId"),
      operateType: "",
      operateDialogVisible: false,
      optOption: false,
      checkedTbHeader: [],
      // 默认
      defaultData: ["进度计划名称"],
      newarr: [],
      tableHeader,
      initTbHeader: tableHeader,
      currId: "" // 当前操作的id
    };
  },
  created() {
    this.getTableColumns();
  },
  // mounted() {
  //   this.confirm();
  // },
  methods: {
    // 获取动态表格展示字段
    getTableColumns() {
      this.$axios.post(`${this.baseURL}base/plan/select/plan/fields/${this.projectId}/${this.userId}`).then((res) => {
        console.log("获取动态表格展示字段----", res);
        let detail = res.data.data;
        if (detail.length > 0) {
          this.checkedTbHeader = [];
          detail.forEach((item) => {
            this.checkedTbHeader.push(item.label);
          });
        } else {
          this.checkedTbHeader = this.defaultData;
        }
      });
    },
    // 编辑
    handleTableEdit(row) {
      if (!this.myOption) {
        this.$message.error("您没有操作权限！");
        return;
      }
      console.log("操作了编辑");
      this.$emit("handleEditBtn", row);
    },
    // 未发布
    handleTablePublish(id) {
      if (!this.myOption) {
        this.$message.error("您没有操作权限！");
        return;
      }
      this.currId = id;
      this.operateType = "publish";
      this.operateDialogVisible = true;
    },
    // 确定发布
    handlePublishSubmit() {
      let data = {
        planId: this.currId
      };
      this.$axios.post(`${this.baseURL}base/plan/release/plan`, qs.stringify(data)).then((res) => {
        if (res.status == "200") {
          this.operateDialogVisible = false;
          this.$message.success("发布成功");
          this.handleOperateBtnClick();
        }
      });
    },
    // 克隆
    handleTableClone(id) {
      if (!this.myOption) {
        this.$message.error("您没有操作权限！");
        return;
      }
      this.currId = id;
      this.operateType = "clone";
      this.operateDialogVisible = true;
    },
    // 确定克隆
    handleCloneSubmit() {
      let data = {
        planId: this.currId
      };
      this.$axios.post(`${this.baseURL}base/plan/clone/plan`, qs.stringify(data)).then((res) => {
        if (res.status == "200") {
          this.operateDialogVisible = false;
          this.$message.success("克隆成功");
          this.handleOperateBtnClick();
        }
      });
    },
    // 删除
    handleTableDelete(id) {
      if (!this.myDel) {
        this.$message.error("您没有删除权限！");
        return;
      }
      this.operateType = "delete";
      this.operateDialogVisible = true;
      this.currId = id;
    },
    // 确定删除
    handleDeleteSubmit() {
      this.$axios.delete(`${this.baseURL}base/plan/remove/${this.currId}`).then((res) => {
        if (res.status == "200") {
          this.operateDialogVisible = false;
          this.$message.success("删除成功");
          this.handleOperateBtnClick();
        }
      });
    },
    handlePublished() {
      return false;
    },
    // 关闭弹窗
    handleDialogClose() {
      this.operateDialogVisible = false;
    },
    // 勾选
    checkedTbOptions(e) {
      console.log("eeeee", e);
      // localStorage.setItem("arr", JSON.stringify(e));
    },
    paginationSizeChange(val) {
      this.$emit("paginationSizeChange", { ...val });
    },
    paginationCurrentPage(val) {
      this.$emit("paginationCurrentPage", { ...val });
    },
    // 操作列
    handleOperateBtnClick() {
      this.$emit("operateClick");
    },
    // 点击行
    rowClick(row, column, event) {
      // console.log(row, column, event, "row, column, event");
      this.$emit("rowClick", row);
    },
    // 确定 修改表格列展示字段
    confirm() {
      let fields = [];
      console.log("tableHeader", this.tableHeader);
      this.tableHeader.forEach((item) => {
        this.checkedTbHeader.forEach((eve) => {
          if (eve == item.label) {
            fields.push(item.prop);
          }
        });
      });
      console.log("fields", fields);
      this.$axios.post(`${this.baseURL}base/plan/plan/fields/${this.projectId}/${this.userId}?fields=${fields}`).then((res) => {
        console.log(res);
        if (res.data.status == "200") {
          this.$message.success("修改成功");
          // this.getTableColumns()
          this.$emit("filterHeader", this.checkedTbHeader);
        }
      });
      this.optOption = false;
    },
    cancelDefault(e) {
      // e.stopImmediatePropagation();
      // e.stopPropagation();
      // e.cancelBubble = true;
    },
    headerClick(c, e) {
      console.log("ssssssssssss", e);
      if (c.property !== "opt") {
        return;
      }
      console.log("33333333322222222ssssssssssss", e);
      this.getTableColumns();
      this.optOption = true;

      e.stopImmediatePropagation();
      e.stopPropagation();
      e.cancelBubble = true;
    },

    renderHeader(h, { column }) {
      console.log("h", h, "column", column);
      let str = "";
      if (column.property === "opt") {
        str = ' <i class="el-icon-s-operation" style="font-size: 18px"></i>';
      }
      return h("div", [
        h("span", {
          domProps: {
            innerHTML: column.label
          },
          style: {
            color: "#d8d8d8",
            fontSize: "16px",
            marginRight: "10px"
          }
        }),
        h("span", {
          domProps: {
            innerHTML: str
          },
          style: {
            color: "#d8d8d8",
            fontSize: "14px",
            marginRight: "10px"
          }
        })
      ]);
    },
    handleEdit(index, row) {
      console.log(23);
      this.$emit("edit", row);
    },
    handleDelete(index, row) {
      this.$emit("delete", row);
    },
    searchList(pag) {
      console.log("pag", pag);
      this.$emit("searchList", pag);
    },
    // 表格表头的样式
    headerBg({ row, column, rowIndex, columnIndex }) {
      let sty = "background:#E3EFF0;" + "text-align:left;color:#fff;font-size:16px;font-weight:400;height:44px;line-height:44px" + "overflow: hidden;text-overflow: ellipsis;white-space: nowrap";
      return sty;
    }
    // tableRowClassName({ row, rowIndex }) {
    //     //条纹变色
    //     if (rowIndex % 2 == 1) {
    //         return "hui1";
    //     } else {
    //         return "lv1";
    //     }
    // },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .my-dialog div.el-dialog {
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding-bottom: 40px;
    text-align: center;
    .content-wrapper {
      margin-bottom: 70px;
      img {
        display: inline-block;
        margin: 10px 0;
      }
      .info div {
        line-height: 2;
        font-size: 18px;
      }
    }
    .btn-footer {
      .el-button {
        height: 34px;
      }
      .cancel-btn {
        margin-left: 20px;
        background: none;
        border: 1px solid #2667db;
      }
    }
  }
}
.tablecom {
  /* position: relative; */
  .tbopt {
    position: absolute;
    right: 17px;
    top: 88px;
    z-index: 99;
    width: 160px;
    background: radial-gradient(#0c104d, #0b2d5a);
    font-size: 14px;
    color: #d8d8d8;
    border: 1px solid #103c7d;
    border-radius: 8px;
    box-shadow: 0px 2px 7px 0px rgba(6, 25, 73, 0.53);
    padding: 17px 17px 70px 17px;
    box-sizing: border-box;
    &-item {
      margin-bottom: 10px;
    }
    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
    ::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #2772f0;
      border-color: #2772f0;
    }
    ::v-deep .el-checkbox__inner:hover {
      border-color: #2772f0;
    }
    ::v-deep span.el-checkbox__label {
      font-size: 14px;
      color: #d8d8d8;
    }
    ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
      font-weight: 400;
      font-size: 14px;
      color: #d8d8d8;
    }
    .btn-click {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border-top: 1px solid #cad9f2;
      padding: 15px 0;
      display: flex;
      justify-content: center;
      .el-button {
        height: 28px;
        font-size: 12px;
        padding: 5px 18px;
      }
    }
  }
}
.tablecom ::v-deep .el-table .caret-wrapper {
  display: none !important;
}
.operate-txt {
  margin: 0 5px;
}
div.el-table .operate-txt.published {
  color: rgba(148, 148, 148, 0.86) !important;
}
.el-table .current-row .operate-txt:not(.published) {
  color: #32c5ff !important;
}
.el-button--text {
  color: #2772f0;
}

.currsor {
  cursor: pointer;
}

::v-deep .el-table .ascending .sort-caret.ascending {
  border-bottom-color: #03c8d2;
}

::v-deep .el-table .descending .sort-caret.descending {
  border-top-color: #03c8d2;
}
.pagination-bar {
  padding-top: 10px;
}

.tablecom ::v-deep .el-table tbody tr:hover > td {
  /* // background:transparent!important */
  background: #eafdfe;
}
/* ::v-deep .hui1 {
  background: #091d46 !important;
}
::v-deep .lv1 {
  background: #0c265a !important;
} */

::v-deep .el-table td.el-table__cell,
::v-deep .el-table th.el-table__cell.is-leaf {
  font-size: 14px;
  text-align: center;
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: none;
}
::v-deep .el-table .el-table__body tr:hover {
  cursor: pointer;
}
::v-deep div.el-table .el-table__body tr:hover span.published {
  color: rgba(148, 148, 148, 0.86) !important;
  cursor: not-allowed;
}
::v-deep div.el-table .current-row .cell {
  color: #32c5ff;
}
::v-deep .my-table .el-table__body tr.current-row > td.el-table__cell {
  background: none !important;
}
::v-deep .my-table tr:nth-child(odd) {
  background: #091d46 !important;
}
::v-deep .my-table tr:nth-child(even) {
  background: #0c265a !important;
}
/* ::v-deep .my-table tr:first-child {
  background: #5E87BE !important;
} */
::v-deep .el-checkbox.is-disabled .el-checkbox__inner {
  background: #777 !important;
  border-color: #777 !important;
}
</style>
